import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import { TFunction } from '@wix/yoshi-flow-editor';
import {
  getFormattedStartDate,
  getOfferedDays,
  getPrice,
} from './serviceDetails';
import { ServiceInfoLayoutOption } from '../../components/BookOnline/Widget/Body/ServiceCard/ServiceInfo/consts';
import { ServiceInfoAlignmentOptions } from '../../types/types';
import { BusinessInfo as LegacyBusinessInfo } from '../../../legacy/types';

export type ServiceInfoDisplayOptions = {
  tagLine?: string;
  offeredDays?: string;
  startDate?: string;
  duration?: string;
  price?: {
    displayedPrice?: string;
    srOnlyPrice?: string;
  };
};

export type DisplayOptions = {
  isTagLineVisible: boolean;
  isServiceOfferedDaysVisible: boolean;
  isServiceStartDateVisible: boolean;
  isServiceDurationVisible: boolean;
  isServicePriceVisible: boolean;
};

export const generateServiceInfoDetails = ({
  displayOptions: {
    isServiceDurationVisible,
    isServiceOfferedDaysVisible,
    isServicePriceVisible,
    isServiceStartDateVisible,
    isTagLineVisible,
  },
  service,
  businessInfo,
  language,
  t,
}: {
  displayOptions: DisplayOptions;
  service: CatalogServiceDto;
  businessInfo: LegacyBusinessInfo;
  language: string;
  t: TFunction;
}): ServiceInfoDisplayOptions => {
  const tagLine = isTagLineVisible ? service.info.tagLine : undefined;
  const offeredDays =
    isServiceOfferedDaysVisible && service.type === ServiceType.GROUP
      ? getOfferedDays(service, t)
      : undefined;
  const startDate =
    isServiceStartDateVisible && service.type === ServiceType.COURSE
      ? getFormattedStartDate({ service, language, t, businessInfo })
      : undefined;
  const duration =
    isServiceDurationVisible &&
    (service.type === ServiceType.GROUP ||
      service.type === ServiceType.INDIVIDUAL)
      ? service.scheduleHeader.durationText
      : undefined;
  const price = isServicePriceVisible
    ? getPrice({ service, language, locale: businessInfo.regionalSettings })
    : undefined;

  return {
    tagLine,
    offeredDays,
    startDate,
    duration,
    price,
  };
};

function testServiceInfoDetailsCenterAlignmentForHorizontal({
  startDate,
  offeredDays,
  price,
  tagLine,
  duration,
}: ServiceInfoDisplayOptions) {
  const numberOfDetailsInMiddleColumn = [
    offeredDays,
    startDate,
    duration,
    price?.displayedPrice,
  ].filter((detail) => detail).length;
  return !tagLine && numberOfDetailsInMiddleColumn < 2;
}

function testServiceInfoDetailsCenterAlignmentForVertical(
  {
    startDate,
    offeredDays,
    price,
    tagLine,
    duration,
  }: ServiceInfoDisplayOptions,
  isServiceDividerVisible: boolean,
) {
  const numberDetails = [
    tagLine,
    offeredDays,
    startDate,
    duration,
    price?.displayedPrice,
  ].filter((detail) => detail).length;
  return !isServiceDividerVisible && numberDetails === 0;
}

export const getDetailsAlignment = ({
  allServiceInfoDetails,
  serviceInfoLayout,
  isServiceDividerVisible,
}: {
  allServiceInfoDetails: ServiceInfoDisplayOptions[];
  isServiceDividerVisible: boolean;
  serviceInfoLayout: ServiceInfoLayoutOption;
}): ServiceInfoAlignmentOptions => {
  return allServiceInfoDetails.every(
    serviceInfoLayout === ServiceInfoLayoutOption.HORIZONTAL
      ? testServiceInfoDetailsCenterAlignmentForHorizontal
      : (serviceInfoDisplayOptions: ServiceInfoDisplayOptions) =>
          testServiceInfoDetailsCenterAlignmentForVertical(
            serviceInfoDisplayOptions,
            isServiceDividerVisible,
          ),
  )
    ? ServiceInfoAlignmentOptions.CENTER
    : ServiceInfoAlignmentOptions.TOP;
};
