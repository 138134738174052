export enum DataHooks {
  ROOT = 'service-info-root',
  DIVIDER = 'service-info-divider',
  SR_ONLY_PRICE = 'service-info-sr-only-price',
}

export enum ServiceInfoLayoutOption {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum ServiceDetailsType {
  OFFERED_DAYS = 'offered-days',
  START_DATE = 'start-date',
  DURATION = 'duration',
  PRICE = 'price',
}
